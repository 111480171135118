import React from "react";
import Container from "../../../reusecore/UI/Container";
import Text from "../../../reusecore/Text";
import PropTypes from "prop-types";
import SectionWrapper, { SectionHeader } from "./service.style";
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl";

const Services = ({ sectionSubTitle, subtitle, sectionImage }) => {
  return (
    <SectionWrapper id="services">
      <Container width="1260px">
        <SectionHeader>
          <Text
            content={<FormattedMessage id="homePrincipalTitle" />}
            {...sectionSubTitle}
          />
          <Text
            content={<FormattedMessage id="homePrincipalDesc" />}
            {...subtitle}
          />
        </SectionHeader>
      </Container>
    </SectionWrapper>
  );
};

Services.prototype = {
  sectionImage: PropTypes.object,
  btnStyle: PropTypes.object,
  secText: PropTypes.object,
  sectionImage1: PropTypes.object,
};

Services.defaultProps = {
  sectionSubTitle: {
    as: "span",
    display: "block",
    textAlign: "center",
    fontSize: "26px",
    letterSpacing: "0.15em",
    fontWeight: "700",
    color: "#84BD00",
    mb: "2rem",
    mt: ["2rem", "-2rem", "2rem", "2rem"],
  },
};

export default injectIntl(Services);
