import styled from "styled-components";

export const ClientsImage = styled.div`
  position: relative;
  padding: 5px 5px;
  flex-shrink: 0;

  img {
    max-width: 160px; 
    height: 60px;
  
    @media only screen and (max-width: 1440px) {
    img{
        width: 100px; 
        height: 50px;
       }
    }
    @media only screen and (max-width: 1360px) {
    img{
      width: 50px; 
      height: 25px;
    }
   }
   @media only screen and (max-width: 991px) {
    img{
        width: 100px; 
        height: 50px;
       }
  }
`;
