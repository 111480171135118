import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Box from "../../../reusecore/Box";
import Image from "../../../reusecore/Image";
import Container from "../../../reusecore/UI/Container";
import { ClientsImage } from "./clients.style";

const ClientsSection = ({ sectionWrapper, secTitleWrapper, row }) => {
  const Data = useStaticQuery(graphql`
    query {
      portfolioJson {
        CLIENTS {
          title
          image {
            publicURL
          }
        }
      }
      portfolioJson {
        CLIENTS1 {
          title
          image {
            publicURL
          }
        }
      }
    }
  `);

  return (
    <Box {...sectionWrapper} as="section">
      <Container noGutter width="1200px">
        <Box {...row} className="test1">
          {Data.portfolioJson.CLIENTS.map((item, index) => (
            <ClientsImage key={`client-${index}`}>
              <Image
                src={item.image.publicURL}
                alt={item.title}
                title={item.title}
              />
            </ClientsImage>
          ))}
        </Box>
        <Box {...row} className="test2">
          {Data.portfolioJson.CLIENTS1.map((item, index) => (
            <ClientsImage key={`client-${index}`}>
              <Image
                src={item.image.publicURL}
                alt={item.title}
                title={item.title}
              />
            </ClientsImage>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

ClientsSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
  row: PropTypes.object,
};

ClientsSection.defaultProps = {
  sectionWrapper: {
    pt: ["0px", "0px", "0px", "0px", "0px"],
    pb: ["60px", "60px", "60px", "60px", "60px"],
  },
  secTitleWrapper: {
    mb: "60px",
  },
  secTitle: {
    as: "span",
    display: "block",
    textAlign: "center",
    fontSize: "25px",
    letterSpacing: "0.15em",
    fontWeight: "700",
    color: "#84BD00",
    mb: "60px",
  },
  secDescription: {
    fontSize: "36px",
    fontWeight: "400",
    color: "#000000",
    lineHeight: "1.5",
    mb: "0",
    textAlign: "center",
  },
  row: {
    flexBox: true,
    flexWrap: "wrap",
    alignItems: "justify",
    justifyContent: "center",
    mb: "10px",
  },
};

export default ClientsSection;
